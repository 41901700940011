'use client'
import type { FC, MouseEvent } from 'react'
import { useEffect, useState } from 'react'
import { type BoxProps } from '@chakra-ui/react'
import GrandNextLink from '../../common/GrandNextLink'
import HoverableComponent from '../../common/HoverableComponent'
import HoverableTextUnderline from '../../common/HoverableTextUnderline'
import type { GrandDividerProps } from '../../common'

export interface MenuItem {
  id: string
  text: string
  path: string
  isCurrent?: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  other?: any[]
  onHoverEnter?: () => void
  onHoverLeave?: () => void
  textComponentProps?: BoxProps
  hoveredBorderColor?: GrandDividerProps['borderColor']
}

const MenuItemComponent: FC<MenuItem> = ({
  onHoverEnter,
  onHoverLeave,
  text,
  path,
  isCurrent,
  onClick,
  textComponentProps,
  hoveredBorderColor
}) => {
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    if (isHovered) {
      onHoverEnter && onHoverEnter()
    } else {
      onHoverLeave && onHoverLeave()
    }
  }, [isHovered, onHoverEnter, onHoverLeave])

  return (
    <GrandNextLink aria-label={text} href={path} textDecoration="none!important" {...{ onClick }}>
      <HoverableComponent
        borderRadius="sm"
        color={isCurrent ? 'primary' : 'text.default'}
        cursor="pointer"
        flexGrow={0}
        fontWeight="medium"
        onHover={setIsHovered}
        px={2}
        py={3}
        w="auto"
        {...textComponentProps}>
        <HoverableTextUnderline
          fontSize="xs"
          text={text.toUpperCase()}
          hoveredBorderColor={hoveredBorderColor}
          {...{ isHovered }}
        />
      </HoverableComponent>
    </GrandNextLink>
  )
}

export default MenuItemComponent
