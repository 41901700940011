import { useMemo } from 'react'
import MenuItemComponent, { type MenuItem } from './MenuItem'

const cssVarName = '--chakra-colors-component-TopNavContent-MenuItem'
const activeColor = `var(${cssVarName}-_active-color, var(--chakra-colors-primary))`
const activeMenuIsOpenedColor = `var(${cssVarName}-_active_menuIsOpened-color, ${activeColor})`

type TopNavContentMenuItemProps = MenuItem & {
  menuIsOpened: boolean
}
/**
 * Allow to specify colors for menu item for all cases in chakra scheme
 * ```typescript
 * const theme = {
 *   colors: {
 *     component: {
 *       TopNavContent: {
 *           MenuItem: {
 *             color: 'white',
 *             _active: {
 *               color: 'red',
 *             },
 *             _active_menuIsOpened: {
 *               color: 'yellow',
 *             },
 *             _menuIsOpened: {
 *               color: 'green'
 *             }
 *           }
 *         },
 *     }
 *   }
 * }
 * ```
 * */
export const TopNavContentMenuItem = ({
  menuIsOpened,
  isCurrent,
  textComponentProps,
  ...props
}: TopNavContentMenuItemProps) => {
  const color = useMemo(() => {
    if (isCurrent) {
      return menuIsOpened ? activeMenuIsOpenedColor : activeColor
    }

    const defaultColor = `var(${cssVarName}-color)`

    return menuIsOpened ? `var(${cssVarName}-_menuIsOpened-color, ${defaultColor})` : defaultColor
  }, [isCurrent, menuIsOpened])

  const hoveredBorderColor = useMemo(
    () => (menuIsOpened ? activeMenuIsOpenedColor : activeColor),
    [menuIsOpened]
  )

  return (
    <MenuItemComponent
      hoveredBorderColor={hoveredBorderColor}
      textComponentProps={{
        ...textComponentProps,
        color
      }}
      {...props}
    />
  )
}
